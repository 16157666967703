<template>
  <div class="navigation-content">
    <a-menu
      style="width: 100%"
      :default-selected-keys="['1']"
      :open-keys="openKeys"
      mode="inline"
      @click="handleClick"
      @openChange="onOpenChange"
      v-model="current"
    >
      <a-sub-menu
        @titleClick="titleClick"
        v-for="item in NavigationsData"
        :key="item.key"
      >
        <span slot="title">
          <a-icon type="mail" />
          <span>{{ item.menuName }}</span>
        </span>
        <a-menu-item
          v-for="children in item.children"
          :key="children.key"
        >{{
          children.menuName
        }}</a-menu-item>
      </a-sub-menu>
    </a-menu>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      current: ["0"],
      openKeys: ["0"],
    };
  },
  computed: {
    ...mapState(["NavigationsData"]),
  },
  mounted() {
    setTimeout(() => {
      this.setTitle();
    }, 1000);
  },
  watch: {
    $route(val) {
      this.setTitle();
    },
  },
  methods: {
    onOpenChange(openKeys) {
      const latestOpenKey = openKeys.find(key => this.openKeys.indexOf(key) === -1);
      if (this.NavigationsData.map(el => el.key).indexOf(latestOpenKey) === -1) {
        // console.log(openKeys, latestOpenKey, '没找到')
        this.openKeys = openKeys;
      } else {
        this.openKeys = latestOpenKey ? [latestOpenKey] : [];
        // console.log(openKeys, latestOpenKey, '找到了')
      }
    },
    handleClick(e) {
      const { keyPath } = e;
      this.$emit("update", keyPath);
    },
    matchBreads(keys, array) {
      if (!array || array.length < 1) {
        return;
      }
      const obj = {};
      for (let i = 0; i < array.length; i++) {
        if (array[i].key === keys) {
          const { menuName, key, menuUrl } = array[i];
          Object.assign(obj, { menuName, key, menuUrl });
          return obj;
        } else {
          if (array[i].children) {
            const result = this.matchBreads(keys, array[i].children);
            if (result) {
              return result;
            }
          }
        }
      }
      return obj;
    },
    titleClick(e) {
      //展开当前集合时
    },
    setTitle() {
      const roteName = this.$route.path;
      for (let i = 0; i < this.NavigationsData.length; i++) {
        const el = this.NavigationsData[i];
        for (let y = 0; y < el.children.length; y++) {
          const el2 = el.children[y];

          if (roteName == el2.menuUrl) {
            this.current = [el2.key];
            document.title = el2.menuName;
            const keyPath = [el2.key, el2.keys_array[0]];
            this.$emit("update", keyPath);
          }
        }
      }
    },
  },
};
</script>

<style lang='scss' >
.navation-content {
  width: 100%;
  height: auto;
}
::v-deep.ant-menu-submenu-selected {
  color: rgb(171, 58, 59);
}
::v-deep.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: rgba(171, 58, 59, 0.3);
}
::v-deep.ant-menu-inline .ant-menu-item::after {
  border-right: 3px solid rgb(171, 58, 59);
}
::v-deep.ant-menu-item-selected {
  color: rgb(171, 58, 59);
}
::v-deep.ant-menu-item:hover {
  color: rgb(171, 58, 59);
}
::v-deep.ant-menu-submenu-title:hover {
  color: rgb(171, 58, 59);
}
</style>