var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        title: "新增词条",
        "ok-text": "确认",
        "cancel-text": "取消",
        width: 700
      },
      on: { cancel: _vm.handleCancel, ok: _vm.handleConfirm },
      model: {
        value: _vm.visible,
        callback: function($$v) {
          _vm.visible = $$v
        },
        expression: "visible"
      }
    },
    [
      _c(
        "a-form-model",
        _vm._b(
          { ref: "ruleForm", attrs: { model: _vm.forms, rules: _vm.rules } },
          "a-form-model",
          _vm.layout,
          false
        ),
        [
          _c(
            "a-form-model-item",
            { attrs: { label: "大币种", prop: "coinKindSid" } },
            [
              _c(
                "a-select",
                {
                  ref: "addse",
                  attrs: {
                    size: "small",
                    "filter-option": _vm.untils.filterOption,
                    showSearch: "",
                    placeholder: "请选择",
                    allowClear: ""
                  },
                  on: {
                    search: _vm.searchData,
                    change: function($event) {
                      return _vm.changeBigCoinSelect($event)
                    }
                  },
                  model: {
                    value: _vm.forms.coinKindSid,
                    callback: function($$v) {
                      _vm.$set(_vm.forms, "coinKindSid", $$v)
                    },
                    expression: "forms.coinKindSid"
                  }
                },
                _vm._l(_vm.currency_big_list, function(item) {
                  return _c(
                    "a-select-option",
                    { key: item.id, attrs: { value: item.sid } },
                    [_vm._v(_vm._s(item.coinKindName))]
                  )
                }),
                1
              )
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "小币种" } },
            [
              _c(
                "a-select",
                {
                  attrs: {
                    size: "small",
                    "filter-option": _vm.untils.filterOption,
                    showSearch: "",
                    placeholder: "请选择",
                    allowClear: ""
                  },
                  on: {
                    change: function($event) {
                      return _vm.changeSelect($event, 2)
                    }
                  },
                  model: {
                    value: _vm.forms.coinKindItemSid,
                    callback: function($$v) {
                      _vm.$set(_vm.forms, "coinKindItemSid", $$v)
                    },
                    expression: "forms.coinKindItemSid"
                  }
                },
                _vm._l(_vm.currency_small_list, function(item) {
                  return _c(
                    "a-select-option",
                    { key: item.id, attrs: { value: item.sid } },
                    [_vm._v(_vm._s(item.coinKindItemName))]
                  )
                }),
                1
              )
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "版别名称" } },
            [
              _c(
                "a-select",
                {
                  attrs: {
                    size: "small",
                    placeholder: "请选择",
                    "filter-option": _vm.untils.filterOption,
                    showSearch: "",
                    allowClear: ""
                  },
                  on: {
                    change: function($event) {
                      return _vm.changeSelect($event, 3)
                    }
                  },
                  model: {
                    value: _vm.forms.coinKindItemVersionSid,
                    callback: function($$v) {
                      _vm.$set(_vm.forms, "coinKindItemVersionSid", $$v)
                    },
                    expression: "forms.coinKindItemVersionSid"
                  }
                },
                _vm._l(_vm.bottles, function(item) {
                  return _c(
                    "a-select-option",
                    { key: item.sid, attrs: { value: item.sid } },
                    [_vm._v(_vm._s(item.coinKindVersionName))]
                  )
                }),
                1
              )
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "词条名称", prop: "name" } },
            [
              _c("a-input", {
                attrs: { size: "small", placeholder: "词条名称" },
                model: {
                  value: _vm.forms.name,
                  callback: function($$v) {
                    _vm.$set(_vm.forms, "name", $$v)
                  },
                  expression: "forms.name"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "词条封面", prop: "coverUrl" } },
            [
              _c(
                "a-upload",
                {
                  attrs: {
                    multiple: true,
                    showUploadList: false,
                    action: _vm.ip + "/file/upload",
                    "list-type": "picture-card"
                  },
                  on: { change: _vm.handleChangeImg }
                },
                [
                  !_vm.forms.coverUrl
                    ? _c(
                        "div",
                        [
                          _c("a-icon", { attrs: { type: "plus" } }),
                          _c("div", { staticClass: "ant-upload-text" }, [
                            _vm._v("上传图片")
                          ])
                        ],
                        1
                      )
                    : _c("img", {
                        staticStyle: { width: "100%" },
                        attrs: { src: _vm.forms.coverUrl }
                      })
                ]
              )
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "词条内容", prop: "content" } },
            [
              _c("WangEditor", {
                attrs: { option: _vm.editorConfig },
                model: {
                  value: _vm.forms.content,
                  callback: function($$v) {
                    _vm.$set(_vm.forms, "content", $$v)
                  },
                  expression: "forms.content"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }