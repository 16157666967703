/*
 * @Author: xu.zhehui 
 * @Date: 2021-09-09 17:44:27 
 * @Last Modified by: xu.zhehui
 * @Last Modified time: 2022-03-14 14:29:34
 */
<template>
    <div class="cms-content">
        <div class="cms-left">
            <div class="cms-header text-center">运营后台</div>
            <Navigation style="flex:1;overflow-y:auto;" @update='updates'/>
        </div>
        <div class="cms-right">
            <div class="cms-header login-set">
                <div>
                    <a-avatar>{{userInfo.userName}}</a-avatar>
                    <a-popconfirm
                        title="确认退出么？"
                        ok-text="是"
                        cancel-text="取消"
                        @confirm="loginOut"
                        @cancel="cancel">
                        <a>退出</a>
                    </a-popconfirm>
                    <a @click="openModal" style='margin-right:5px'>修改密码</a>
                    
                </div>
            </div>
             <Breadcrumb :result='breadList' :userInfo="userInfo" />
            <div class="cms-router" id="blackList"><router-view style="width:100%;height:100%;"></router-view></div>

        </div>

        <a-modal v-model="visible" title="系统账号" ok-text="确认" cancel-text="取消" @cancel='resetForms' @ok="hideModal">
            <a-form-model
                ref="ruleForm"
                :model="forms"
                :rules="rules"
            >


                <a-form-model-item label="原密码" prop="oldPwd" >
                    <a-input placeholder='请输入密码' v-model="forms.oldPwd" type="password"></a-input>
                </a-form-model-item>

                <a-form-model-item label="新密码" prop="newPwd" >
                    <a-input placeholder='请输入密码' v-model="forms.newPwd" type="password"></a-input>
                </a-form-model-item>
            </a-form-model>
        </a-modal>

    </div>
</template>

<script>
import Navigation from '../components/Navigation/index'
import Breadcrumb from '../components/Breadcrumb/index'
import {mapState,mapMutations,mapActions} from 'vuex'

export default {
  data(){
    return {
      rules:{
        newPwd:[{ required: true, message: 'please enter newPwd', trigger: 'blur' }],
        oldPwd:[{ required: true, message: 'please enter oldPwd', trigger: 'blur' }],
      },
      forms:{
      },
      visible:false,
      maskDiv: {}, // 当前显示的水印div节点DOM对象
    }
  },
  watch:{

    // 监听传入水印文本变化
    inputText() {
      this.$nextTick(() => {
        this.removeMaskDiv();
      });
    }
  },
  mounted() {
    // 确认DOM渲染后再执行
    this.$nextTick(() => {
      // 创建水印节点
      this.init();
      if (!this.inputAllowDele) {
        // 设置水印节点修改的DOM事件
        this.Monitor();
      }
    });
  },
  created(){
    this.updataMenu()
    this.setBreads()
    let userInfo = localStorage.getItem('userInfo')
    if(userInfo){
      this.updateUserInfo(JSON.parse(userInfo))
    }
    var obj = JSON.parse(userInfo)
    var userName = obj.userName;
    this.inputText = userName +" " +(new Date().getMonth()+1)+ "/" + new Date().getDate()
  },
  computed:{
    ...mapState(['NavigationsData','breadList','userInfo']),
  },
  methods:{
    ...mapMutations(['updateBread','setBreads','updateUserInfo']),
    ...mapActions(['updataMenu']),
    // 修改密码
    resetForms(){
      this.forms = {}
      this.$refs.ruleForm.resetFields();
    },
    hideModal(){
      this.$refs.ruleForm.validate(valid => {
        if(valid){
          this.submit(this.forms)
        }else{
          return false;
        }
      });
    },
    submit(forms){
      const post_url = '/platform/platformaccount/editPwd'
      this.axios.post(post_url,{...forms}).then(res=>{
        if(res.status == '200'){
          this.$message.success(res.message)
          this.visible = false
          this.getData(this.params)
        }
      })
    },
    openModal(){
      this.visible = true
      this.forms = {}
    },
    updates(o){
      o.reverse()
      const current_index = o[0]||0//优化处理，减少递归调用次数
      const result = o.map(rows=>{
        return this.matchBreads(rows,[this.NavigationsData[current_index]])
      })
      if(result&&result[result.length-1].menuUrl){
        this.$router.push({path:result[result.length-1].menuUrl})//此处可能会有一些小问题，可以优化
      }
      this.updateBread(result)

    },
    matchBreads(keys,array){
      if(!array||array.length<1){return}
      const obj = {}
      for(let i = 0;i<array.length;i++){
        if(array[i].key === keys){
          const {menuName,key,menuUrl} = array[i]
          Object.assign(obj,{menuName,key,menuUrl})
          return obj
        }else{
          if(array[i].children){
            const result = this.matchBreads(keys,array[i].children)
            if(result){
              return result
            }
          }
        }
      }
      return obj
    },
    cancel(){},
    loginOut(){
      this.axios('/platform/page/platformLogOut').then(res=>{
        if(res.status == '200'){
          this.$message.success(res.message)
          localStorage.removeItem('token')
          localStorage.removeItem('userInfo')
          setTimeout(()=>location.reload(),500)
        }
      })
    },
    // 水印控件
    init() {
      let canvas = document.createElement("canvas");
      canvas.id = "canvas";
      canvas.width = "200"; // 单个水印的宽高
      canvas.height = "130";
      this.maskDiv = document.createElement("div");
      let ctx = canvas.getContext("2d");
      ctx.font = "normal 18px Microsoft Yahei"; // 设置样式
      ctx.fillStyle = "rgba(112, 113, 114, 0.08)"; // 水印字体颜色
      ctx.rotate((30 * Math.PI) / 180); // 水印偏转角度
      ctx.fillText(this.inputText, 30, 20);
      let src = canvas.toDataURL("image/png");
      this.maskDiv.style.position = "fixed";
      this.maskDiv.style.zIndex = "9999";
      this.maskDiv.id = "_waterMark";
      this.maskDiv.style.top = "30px";
      this.maskDiv.style.left = "0";
      this.maskDiv.style.height = "100%";
      this.maskDiv.style.width = "100%";
      this.maskDiv.style.pointerEvents = "none";
      this.maskDiv.style.backgroundImage = "URL(" + src + ")";
      // 水印节点插到body下
      document.getElementById("blackList").appendChild(this.maskDiv);
    },
    Monitor() {
      let body = document.getElementsByTagName("body")[0];
      let options = {
        childList: true,
        attributes: true,
        characterData: true,
        subtree: true,
        attributeOldValue: true,
        characterDataOldValue: true
      };
      let observer = new MutationObserver(this.callback);
      observer.observe(body, options); // 监听body节点
    },
    // DOM改变执行callback
    callback(mutations, observer) {
      // 当attribute属性被修改
      if (mutations[0].target.id === "_waterMark") {
        try{
          this.removeMaskDiv();
        }catch(e){
        }

      }
      // 当id被改变时
      if (mutations[0].attributeName === "id") {
        try{
          this.removeMaskDiv();
          this.init();
        }catch(e){

        }

      }
      // 当节点被删除
      if (
          mutations[0].removedNodes[0] &&
          mutations[0].removedNodes[0].id === "_waterMark"
      ) {
        this.init();
      }
    },
    /* public */
    // 手动销毁水印DOM
    removeMaskDiv() {
      document.body.removeChild(this.maskDiv);
    },
    // 手动生成水印
    createMaskDiv() {
      this.init();
    }
  },
  destroy() {
    // 组件销毁时去除生成在body节点下的水印节点
    if (this.inputDestroy) {
      this.removeMaskDiv();
    }
  },
    components:{Navigation,Breadcrumb}
}
</script>

<style lang="scss">
.cms-content{display: flex;height:100%;
    .cms-left{ flex-shrink: 0; width:200px;border-right:5px solid #fff;height:100%;display: flex;flex-direction: column;}
    .cms-right{width:calc( 100vw - 200px); flex:1;height:100%;display: flex;flex-direction: column;}
}
.cms-header{width:100%;height:60px;background:rgb(171,58,59)}
.text-center{display: flex;justify-content: center;align-items: center;color:#fff;font-size:30px;}
.login-set{display: flex;justify-content:flex-end;align-items: center;padding-right:20px;
    a{margin-left:10px;color:#fff;}
}
.cms-router{flex:1;background: rgb(242,242,242);padding:10px;overflow: hidden;}
.map-margin{margin:5px;}

</style>