<template>
  <!-- 新增编辑 -->
  <a-modal
      v-model="visible"
      title="新增词条"
      ok-text="确认"
      cancel-text="取消"
      @cancel="handleCancel"
      @ok="handleConfirm"
      :width="700"
  >
    <a-form-model
        ref="ruleForm"
        :model="forms"
        :rules="rules"
        v-bind="layout"
    >
      <a-form-model-item label="大币种" prop="coinKindSid">
        <a-select
            size="small"
            @search="searchData"
            ref="addse"
            v-model="forms.coinKindSid"
            :filter-option="untils.filterOption"
            showSearch
            @change="changeBigCoinSelect($event)"
            placeholder="请选择"
            allowClear
        >
          <a-select-option
              v-for="item of currency_big_list"
              :key="item.id"
              :value="item.sid"
          >{{ item.coinKindName }}</a-select-option
          >
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="小币种">
        <a-select
            size="small"
            :filter-option="untils.filterOption"
            showSearch
            v-model="forms.coinKindItemSid"
            @change="changeSelect($event, 2)"
            placeholder="请选择"
            allowClear
        >
          <a-select-option
              v-for="item of currency_small_list"
              :key="item.id"
              :value="item.sid"
          >{{ item.coinKindItemName }}</a-select-option
          >
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="版别名称">
        <a-select
            size="small"
            placeholder="请选择"
            :filter-option="untils.filterOption"
            showSearch
            v-model="forms.coinKindItemVersionSid"
            @change="changeSelect($event, 3)"
            allowClear
        >
          <a-select-option
              v-for="item of bottles"
              :key="item.sid"
              :value="item.sid"
          >{{ item.coinKindVersionName }}</a-select-option
          >
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="词条名称" prop="name">
        <a-input size="small" placeholder="词条名称" v-model="forms.name" />
      </a-form-model-item>
      <a-form-model-item label="词条封面" prop="coverUrl">
        <a-upload
            :multiple="true"
            :showUploadList="false"
            :action="ip + '/file/upload'"
            list-type="picture-card"
            @change="handleChangeImg"
        >
          <div v-if="!forms.coverUrl">
            <a-icon type="plus" />
            <div class="ant-upload-text">上传图片</div>
          </div>
          <img v-else :src="forms.coverUrl" style="width: 100%" />
        </a-upload>
      </a-form-model-item>
      <a-form-model-item label="词条内容" prop="content">
        <WangEditor :option="editorConfig" v-model="forms.content"/>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>
<script>
import {mapState} from "vuex"
import untils from "@/untils"
import WangEditor from "@/components/WangEditor/index.vue"

export default {
  components: {WangEditor},
  data() {
    return {
      untils,
      currency_big_list: [],
      currency_small_list: [],
      bottles: [],
      visible: false,
      editorConfig: {
        height: 300
      },
      forms: {
        name: undefined,
        coverUrl: undefined,
        content: undefined,
        coinKindItemSid: undefined,
        coinKindItemVersionSid: undefined,
      },
      layout: {
        labelCol: { span: 4 }, //标签占5份  共24份
        wrapperCol: { span: 20 },
      },
      rules: {
        name: [{ required: true, message: "词条名称必须输入", trigger: "change" }],
      },
    }
  },
  watch: {
    "forms.coinKindSid":{
      handler(val){
        if (val === "") {
          delete this.forms.coinId
          delete this.forms.versionId
          this.$set(this.forms, "coinKindItemSid", [])
          this.$set(this.forms, "coinKindItemVersionSid", [])
        }
      },
      deep: true
    },
    "forms.coinKindItemSid": {
      handler(val) {
        if (val && val.length === 0) {
          delete this.forms.versionId;
          this.$set(this.forms, "coinKindItemVersionSid", [])
        }
      },
      deep: true,
    },
    "forms.coinKindItemVersionSid": {
      handler(val) {
        if (val && val.length === 0) {
          delete this.forms.versionId;
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapState(['ip'])
  },
  methods: {
    /** 展示 */
    show() {
      this.visible = true
    },
    /** 确定提交 */
    async handleConfirm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.submit(this.forms);
        } else {
          return false;
        }
      });
    },
    /** 提交 */
    submit(forms) {
      const url = "/dq_admin/knowledgeItem/addOrEdit"
      this.axios.post(url, forms).then((res) => {
        if (res.status === "200") {
          this.$message.success(res.message);
          this.visible = false;
          this.handleCancel()
        }
      });
    },
    /** 上传词条封面 */
    handleChangeImg(res) {
      try {
        const { response } = res.file;
        if (response.data) {
          const { data } = response;
          if (data) {
            this.$set(this.forms, "coverUrl", data);
          }
        }
      } catch (e) {}
    },
    /** 处理服务 */
    replaceSid(array, key, name) {
      return array.filter((item) => item[key] === name);
    },
    /** 选择大币种，小币种 */
    changeSelect(sid, type) {
      if (sid) {
        if (type === 1) {
          const result = this.replaceSid(this.currency_big_list, "coinKindName", sid);
          if (result && result.length > 0) {
            sid = [result[0].sid];
          }
          this.forms.coinKindSid = sid;
          this.axios("/dq_admin/kind/getItemAndVersion", {
            params: { sid: sid },
          }).then((res) => {
            const { coinItem, coinItemVersion } = res.data;
            this.bottles = coinItemVersion;
            this.currency_small_list = coinItem;
          });
        } else if (type === 2) {
          const result = this.replaceSid(this.currency_small_list, "coinKindItemName", sid);
          if (result && result.length > 0) {
            sid = [result[0].sid];
          }
          this.forms.coinKindItemSid = sid;
          this.$set(this.forms, "coinKindItemVersionSid", [])
          this.axios("/dq_admin/kinditem/getInnerVersionList", {
            params: { sid: sid },
          }).then((res) => {
            const { data } = res;
            this.bottles = data;
          });
        } else {
          // 版别
          const result = this.replaceSid(this.bottles, "coinKindVersionName", sid);
          if (result && result.length > 0) {
            sid = [result[0].sid];
          }
          const bottle = this.bottles.find((el) => {
            return sid === el.sid;
          });
          this.forms.versionId = bottle.id;
          this.$set(this.forms, "name", bottle.coinKindVersionName)
          this.forms.coinKindItemVersionSid = sid;
        }
      }
    },
    /** 搜索大币种 */
    searchData(inner) {
      this.axios("/dq_admin/kind/list", {
        params: { coinName: inner },
      }).then((res) => {
        if (res.status === "200") {
          const { records } = res.data;
          this.currency_big_list = records;
        }
      });
    },
    /** 修改选择大币种 */
    changeBigCoinSelect(sid) {
      if (sid && sid.length > 1) {
        // 置空
        this.$set(this.forms, "coinKindItemSid", [])
        this.$set(this.forms, "coinKindItemVersionSid", [])
        const bigCoin = this.currency_big_list.find((el) => {
          return sid === el.sid;
        });
        this.forms.coinId = bigCoin.id;
        this.axios("/dq_admin/kind/getItemAndVersion", {
          params: { sid: sid },
        }).then((res) => {
          const { coinItem, coinItemVersion } = res.data;
          this.bottles = coinItemVersion;
          this.currency_small_list = coinItem;
        });
      }
    },
    /** 关闭 */
    handleCancel() {
      this.forms = {
        name: undefined,
        coverUrl: undefined,
        content: undefined,
        coinKindItemSid: undefined,
        coinKindItemVersionSid: undefined,
      }
    },
  }
}
</script>