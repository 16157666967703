<template>
  <div class="breadcrumb-attrs">
    <a-breadcrumb separator=">">
      <a-breadcrumb-item style="margin-left:10px">斗泉后台</a-breadcrumb-item>
      <a-breadcrumb-item v-for="item of breadList" :key="item.key">{{item.menuName}}</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="mr-20">
      <a-button
          size="small"
          type="primary"
          v-if="![27].includes(userInfo.id)"
          @click="handleAddEntryPopup"
      >新增词条</a-button>
    </div>
<!--  新增词条弹窗  -->
    <AddEntryPopup ref="addEntryPopupEl"/>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import AddEntryPopup from "@/components/AddEntryPopup/index.vue"
export default {
  components: {AddEntryPopup},
  props:{
    userInfo: {
      type: Object
    },
    result:{
      type:Array,default:null,
    }
  },
  data(){
    return {
    }
  },
  computed:{
    ...mapState(['NavigationsData','breadList']),
  },
  methods: {
    /** 新增词条弹窗 */
    handleAddEntryPopup() {
      this.$refs.addEntryPopupEl.show()
    }
  }
}
</script>

<style lang="scss" scoped>
.breadcrumb-attrs{width:100%;height:40px;display: flex;justify-content: space-between;align-items:center;}
</style>