var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navigation-content" },
    [
      _c(
        "a-menu",
        {
          staticStyle: { width: "100%" },
          attrs: {
            "default-selected-keys": ["1"],
            "open-keys": _vm.openKeys,
            mode: "inline"
          },
          on: { click: _vm.handleClick, openChange: _vm.onOpenChange },
          model: {
            value: _vm.current,
            callback: function($$v) {
              _vm.current = $$v
            },
            expression: "current"
          }
        },
        _vm._l(_vm.NavigationsData, function(item) {
          return _c(
            "a-sub-menu",
            { key: item.key, on: { titleClick: _vm.titleClick } },
            [
              _c(
                "span",
                { attrs: { slot: "title" }, slot: "title" },
                [
                  _c("a-icon", { attrs: { type: "mail" } }),
                  _c("span", [_vm._v(_vm._s(item.menuName))])
                ],
                1
              ),
              _vm._l(item.children, function(children) {
                return _c("a-menu-item", { key: children.key }, [
                  _vm._v(_vm._s(children.menuName))
                ])
              })
            ],
            2
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }